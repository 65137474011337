import headerProducts from "@common/components/header-products";
import textarea from "@common/components/textarea/textarea";
import bHorizontalHercentage from "@common/components/charts/percentage-horizontal";
import datetimepicker from "@common/components/datetimepicker/datetimepicker";
import timepicker from "@common/components/datetimepicker/timepicker";
import bAddress from "@common/components/Address/address";
import bPdf from "@common/components/pdf/pdf";
import bPdfLandscape from "@common/components/pdf/pdf_landscape";
import bcheckbox from "@common/components/checkbox/checkbox";
import bDoc from "@common/components/doc/toDoc";
import * as filter from "@common/components/filter/filter";
import bLocationSelector from "@common/components/locations/locations";
import uAddress from "@common/components/Address/addressNew";
import bTree from '@common/components/tree/bTree'
import bNodeDrilldown from '@common/components/drilldown/node';
import bSkuDrilldown from '@common/components/drilldown/sku';
import statusremarks from "@common/components/statusremarks/statusremarks";
import orderTree from '@common/components/tree/orderTree.vue';
import node from '@common/components/node/node.vue';
import sku from '@common/components/sku/sku.vue';
import iwoTree from '@common/components/tree/iwoTree.vue';
import minifiedText from '@common/components/minified_text/minifiedText.vue';
const GlobalComponents = {
    resgister(Vue) {
        Vue.component('header-products', headerProducts);
        Vue.component('b-textarea', textarea);
        Vue.component('b-pdf', bPdf);
        Vue.component('b-checkbox', bcheckbox);
        Vue.component('b-horizontal-percentage', bHorizontalHercentage);
        Vue.component('b-datetimepicker', datetimepicker);
        Vue.component('b-timepicker', timepicker);
        Vue.component('b-address', bAddress);
        Vue.component('u-address', uAddress);
        Vue.component('b-doc', bDoc);
        Vue.component('b-location-selector', bLocationSelector);
        Vue.component('b-tree', bTree);
        Vue.component('b-pdf-landscape', bPdfLandscape);
        Vue.component('b-node-drilldown', bNodeDrilldown);
        Vue.component('b-sku-drilldown', bSkuDrilldown);
        Vue.component('b-statusremarks', statusremarks);
        Vue.component('b-order-tree', orderTree);
        //Vue.component('b-tree', bTree);
        Vue.component('b-node-selector', node);
        Vue.component('b-sku-selector', sku);
        Vue.component('b-iwo-tree', iwoTree);
        Vue.component('b-minifiedText', minifiedText);
        filter.resgisterFilter(Vue);
    }
}

export default GlobalComponents