<template>
  <div :title="text">
    <div class="text">{{ firstLine }}</div><br/>
    <div class="text" v-if="secondLine">{{ secondLine }}</div>
    
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    maxLengthFirstLine: {
      type: Number,
      default: 30
    },
    maxLengthSecondLine: {
      type: Number,
      default: 30
    }
  },
  computed: {
    firstLine() {
      // Truncate the first line based on maxLengthFirstLine
      return this.text.length > this.maxLengthFirstLine
        ? this.text.substring(0, this.maxLengthFirstLine).trim()
        : this.text.trim();
    },
    secondLine() {
      // Get the remaining text after the first line and truncate based on maxLengthSecondLine
      const remainingText = this.text.substring(this.maxLengthFirstLine).trim();
      return remainingText.length > this.maxLengthSecondLine
        ? remainingText.substring(0, this.maxLengthSecondLine).trim() + '...'
        : remainingText;
    }
  }
};
</script>

<style scoped>
div {
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  .text{
    padding-top:5px;
    line-height:20px
  }
}
</style>
